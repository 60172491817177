import axios, {AxiosError, AxiosRequestConfig} from 'axios'

import instance from "./axiosInstance"

let API_URL = "http://127.0.0.1:8000/api/";

if (process.env.REACT_APP_BACKEND_SERVER) {
	console.log("process.env.REACT_APP_BACKEND_SERVER found in axiosInterceptor, setting to: ", process.env.REACT_APP_BACKEND_SERVER)
  API_URL = process.env.REACT_APP_BACKEND_SERVER;
}

const setUpInterceptor = (store: any) => {
	const handleError = async (error: AxiosError) => {
		return Promise.reject(error)
	}

	axios.interceptors.request.use(
		async (config: any | AxiosRequestConfig) => {
			// Default config options
			config.headers.Authorization = store.getState().account.token;
			config.baseURL = API_URL
			config.headers['Content-Type'] = 'application/json'
			return config
		}
	)

	instance.interceptors.response.use((response) => response, handleError)
}

export default setUpInterceptor
